// Ultra-Light
@font-face {
  font-family: Public Sans;
  font-weight: 100;
  src: url("../font/public-sans/PublicSans-VariableFont_wght.ttf");
}

// Light
@font-face {
  font-family: Public Sans;
  font-weight: 200;
  src: url("../font/public-sans/PublicSans-VariableFont_wght.ttf");
}

// Regular
@font-face {
  font-family: Public Sans;
  font-weight: 400;
  src: url("../font/public-sans/PublicSans-VariableFont_wght.ttf");
}

// Bold 
@font-face {
  font-family: Public Sans;
  font-weight: 700;
  src: url("../font/public-sans/PublicSans-VariableFont_wght.ttf");
}
