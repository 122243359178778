// light
@font-face {
  font-family: Lora;
  font-weight: 200;
  src: url("/assets/font/lora/Lora-VariableFont_wght.ttf");
}

// normal
@font-face {
  font-family: Lora;
  font-weight: 400;
  src: url("/assets/font/lora/static/Lora-Regular.ttf");
}

// medium
@font-face {
  font-family: Lora;
  font-weight: 500;
  src: url("/assets/font/lora/static/Lora-Medium.ttf");
}

// semibold
@font-face {
  font-family: Lora;
  font-weight: 600;
  src: url("/assets/font/lora/static/Lora-SemiBold.ttf");
}

// SemiBold
@font-face {
  font-family: Lora;
  font-weight: 700;
  src: url("/assets/font/lora/static/Lora-Bold.ttf");
}
